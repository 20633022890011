<template>
  <DxDataGrid
    ref="dataGrid"
    :data-source="props.dataSource"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :show-column-lines="true"
    :show-row-lines="true"
    :show-borders="true"
    :row-alternation-enabled="false"
    :hover-state-enabled="true"
    :column-hiding-enabled="mediaQuery.lessThanLg()"
    :on-row-dbl-click="OnRowDoubleClick"
    column-resizing-mode="nextColumn"
    @toolbar-preparing="onToolbarPreparing"
    @exporting="onExporting"
    :element-attr="{ id: 'datagrid' }"
  >
    <DxEditing
      v-if="props.enabledActions"
      :allow-updating="auth.getPermissionToEdit"
      :allow-adding="auth.getIsAdmin"
      :use-icons="true"
      mode="popup"
    />

    <DxColumn
      type="buttons"
      fixed-position="right"
      :visible="props.enabledActions"
    >
      <slot name="button"></slot>
      <DxButton name="edit" :on-click="props.EditButtonFunction" />
    </DxColumn>

    <slot name="column"></slot>

    <slot name="footer"></slot>

    <DxColumnFixing :enabled="true" />
    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxSearchPanel :visible="true" />
    <DxGroupPanel visible="auto"/>
    <DxSorting mode="multiple" />
    <DxPaging :page-size="10" :enabled="true" />
    <DxScrolling row-rendering-mode="virtual" />
    <DxLoadPanel :enabled="true" />

    <DxPager
      :visible="true"
      :show-page-size-selector="true"
      :allowed-page-sizes="[10, 20, 'all']"
      :show-navigation-buttons="true"
      :show-info="true"
    />
  </DxDataGrid>
</template>
<script lang="ts" src="./index.ts"></script>

<style lang="postcss" scoped>
#datagrid {
  @apply shadow-md m-1;
}

.dx-state-hover{
  cursor: pointer;
}
</style>