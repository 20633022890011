import { documentoStore } from '../../store/modules/documentos';
import { clienteStore } from './../../store/modules/clientes/index';
import { authStore } from './../../store/modules/auth/index';
import { defineComponent, ref } from "vue";
import {
  DxDataGrid,
  DxColumn,
  DxColumnChooser,
  DxFilterRow,
  DxSearchPanel,
  DxGroupPanel,
  DxColumnFixing,
  DxHeaderFilter,
  DxSorting,
  DxPaging,
  DxPager,
  DxScrolling,
  DxLoadPanel,
  DxEditing,
  DxButton
} from 'devextreme-vue/ui/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { saveAs } from 'file-saver'
import ExcelJS from 'exceljs'
import mediaQuery from '../../helpers/media-query';
import validatorsInput from '../../helpers/validators-input';
import { meses } from '../../helpers/data-filter.json'

export default defineComponent({
  name: 'DatagridFactory',
  props: {
    dataSource: {
      required: true
    },
    AddButtonFunction: {
      type: Function
    },
    EditButtonFunction: {
      type: Function,
    },
    OnRowDoubleClick: {
      type: Function
    },
    ButtonRefreshToolbarFunction: {
      default: null,
      type: Object
    },
    enabledActions: {
      type: Boolean,
      default: true
    },
    nameToExport: {
      default: 'Documento'
    }
  },
  setup(props) {
    const auth = authStore();
    const cliente = clienteStore();
    const docStore = documentoStore()
    const dataGrid = ref();
    function onToolbarPreparing(e: any) {
      const toolbarItems = e.toolbarOptions.items;

      for (let i = 0; i < toolbarItems.length; i++) {
        const item = toolbarItems[i];
        if (item.name === "addRowButton") {
          item.options.onClick = props.AddButtonFunction;
          break;
        }
      }

      if (props.ButtonRefreshToolbarFunction) {
        e.toolbarOptions.items.unshift(props.ButtonRefreshToolbarFunction)
      }

      if (!props.enabledActions) {
        e.toolbarOptions.items.unshift(ButtonExportPdf)
      }
    }

    const ButtonExportPdf = {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'exportpdf',
        hint: 'Exportar para PDF',
        onClick: () => onExportingPDF()
      }
    }

    function onExporting(e: any) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(props.nameToExport)

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${props.nameToExport}.xlsx`);
        })
      })
      e.cancel = true;
    }

    function onExportingPDF() {
      const doc = new jsPDF({ orientation: 'l', format: 'A4' })
      const totalPagesExp = '{total_pages_count_string}'
      const a = {
        theme: 'grid',
        headStyles: {
          fontStyle: 'bold',
          textColor: [255, 255, 255]
        },
        styles: { fontSize: 9 },
        didDrawPage: function (data: any) {
          // HEADER

          doc.setFontSize(14);
          doc.setFont('helvetica', 'bold')
          doc.text(`Relatório ${props.nameToExport}`, data.settings.margin.left, 10);

          doc.setFontSize(9);
          doc.setFont('helvetica', 'italic')
          if(cliente.clienteSelected.nomeFantasia){
            doc.text(`${cliente.clienteSelected.nomeFantasia} - ${validatorsInput.formataCpfCnpj(cliente.clienteSelected.cnpjCpf)}`,
              data.settings.margin.left,
              15);
          }else if(docStore.filter.Cnpj !== null){
            doc.text(`Cnpj - ${validatorsInput.formataCpfCnpj(docStore.filter.Cnpj)}`,
              data.settings.margin.left,
              15);
          }

          doc.setFontSize(8);
          doc.setFont('helvetica', 'bold')
          if (docStore.filter.Mes) {
            doc.text(`Período: ${meses[docStore.filter.Mes].name} / ${docStore.filter.Ano}`,
              data.settings.margin.left,
              20);
          }
          // FOOTER

          let str: any = 'Página ' + doc.getNumberOfPages()
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' de ' + totalPagesExp
          }
          doc.setFontSize(9)

          const pageSize = doc.internal.pageSize
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          doc.setFont('helvetica', 'italic')
          doc.text('eSistem. A Solução Completa na medida certa!  (0800 591 3107)', data.settings.margin.left, pageHeight - 10);
          doc.text(str, data.settings.margin.right + 260, pageHeight - 10)
        },
        margin: { top: 27, left: 7, right: 7 },
      }

      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: dataGrid.value.instance,
        autoTableOptions: a
      }).then(() => {
        doc.setLanguage('pt-BR')

        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp)
        }

        doc.save(props.nameToExport)
      })
    }

    return {
      props,
      auth,
      dataGrid,
      onToolbarPreparing,
      onExporting,
      mediaQuery
    }
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxColumnChooser,
    DxFilterRow,
    DxSearchPanel,
    DxGroupPanel,
    DxColumnFixing,
    DxHeaderFilter,
    DxSorting,
    DxPaging,
    DxPager,
    DxScrolling,
    DxLoadPanel,
    DxEditing,
    DxButton,
  }
})